<template>
  <div class="playPageWrap">
    <m-layout-bar>
      <div class="playWrap">
        <div class="bannerWrap">
          <img :src="require('../assets/image/play/banner.png')" alt />
          <!-- 轮播 -->
          <div class="bannerBox iSwiperWrap">
            <div id="bannerMenu" class="swiper-container gallery-thumbs">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide swiper-bottom"
                  :class="item.activeName"
                  v-for="(item,index) of bannerImg"
                  :key="index"
                >
                  <div class="menuThumbWrap" @click="goNavArea(item.id)">
                    <div class="imgWrap trans">
                      <img :src="require('../assets/image/play/'+item.url+'')" alt />
                    </div>
                    <div class="infoWrap">
                      <div class="gap"></div>
                      <span class="title">{{item.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Arrows -->
            <div class="btnWrap">
              <div class="swiper-button-next swiper-button-white"></div>
              <div class="swiper-button-prev swiper-button-white"></div>
            </div>
          </div>
          <!-- 轮播end -->

          <!-- 吸顶菜单 -->
          <div class="fixedMenus" :class="barFixed?'show':''">
            <a
              class="fixedMenu liuLiuLeYuan trans"
              :class="{'active':activeName==='liuLiuLeYuan'}"
              data-name="liuLiuLeYuan"
              @click="clickMenuItem('gLiuLiuLeYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              溜溜乐园
            </a>
            <a
              class="fixedMenu shiHu trans"
              :class="{'active':activeName==='shiHu'}"
              data-name="shiHu"
              @click="clickMenuItem('gShiHuWenHuaYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              石斛文化园
            </a>
            <a
              class="fixedMenu nongGeng trans"
              :class="{'active':activeName==='nongGeng'}"
              data-name="nongGeng"
              @click="clickMenuItem('gNongGengLeYuan',$event)"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              农耕乐园
            </a>
          </div>
          <!-- 菜单 -->
          <!-- <div id="bannerMenu" class="menusWrap">
            <a href="#gLiuLiuLeYuan" id="mliuliuLeYuan" class="menuWrap liuliuLeYuan picGroup">
              <div class="imgWrap ilarge trans">
                <img :src="require('../assets/image/play/c_1.png')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">溜溜乐园</span>
              </div>
            </a>
            <a id="mShiHu" href="#gShiHuWenHuaYuan" class="menuWrap shiHu picGroup">
              <div class="imgWrap ilarge trans">
                <img :src="require('../assets/image/play/c_2.png')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">石斛文化园</span>
              </div>
            </a>
            <a id="mNongGeng" href="#gNongGengLeYuan" class="menuWrap nongGeng picGroup">
              <div class="imgWrap ilarge trans">
                <img :src="require('../assets/image/play/c_3.png')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">农耕乐园</span>
              </div>
            </a>
          </div>-->
        </div>
        <!-- 溜溜乐园 -->
        <div id="gLiuLiuLeYuan" class="liuLiuLeYuanWrap group">
          <!-- 溜溜乐园---题目---------- -->
          <div class="titleWrap">
            <img class="titleLogo" :src="require('../assets/image/play/g1_title.png')" alt />
          </div>
          <!-- 溜溜乐园----简介--------- -->
          <div class="contentWrap">
            <div class="summaryWrap">
              <div class="layout_left">
                <div class="titleWrap">
                  <span class="title textline1">溜溜乐园</span>
                  <span class="subtitle textline1">LIU LIU LE YUAN</span>
                  <div class="gap"></div>
                </div>
                <div
                  class="detailWrap textline7"
                >溜溜乐园是一个充满欢乐与童趣的地方，取石斛外形弯溜溜，口感滑 溜溜之意而命名，颠覆国内无动力乐园的固有概念，是目前中国规模最大的溜溜主题乐园，拥有花海溜溜、世界风溜溜、虎溜溜三大主题区，把惊险、刺激、美食、美景一网打尽。</div>
              </div>
              <div class="layout_right">
                <img :src="require('../assets/image/play/g1_banner.png')" alt />
              </div>
            </div>
          </div>
          <!-- 溜溜乐园---图片展示------- -->
          <div class="picsGroups group">
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g1_pic1.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title textline1">虎溜溜</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g1_pic2.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title textline1">溜溜塔</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g1_pic3.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">花海溜溜</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g1_pic4.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">世界风溜溜</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 石斛文化园 -->
        <div id="gShiHuWenHuaYuan" class="shiHuWenHuaYuanWrap group">
          <div class="titleWrap">
            <img class="titleLogo" :src="require('../assets/image/play/g2_tilte.png')" alt />
          </div>
          <!-- 石斛文化园----简介--------- -->
          <div class="contentWrap">
            <div class="summaryWrap">
              <div class="layout_left">
                <div class="titleWrap">
                  <span class="title textline1">石斛文化园</span>
                  <span class="subtitle textline1">SHI HU WEN HUA YUAN</span>
                  <div class="gap"></div>
                </div>
                <div class="detailWrap textline7">
                  雁荡山好，石斛珍宝，位列“九大仙草”之首的雁荡山铁皮石斛
                  就像西湖龙井一样拥有一张“金名片”—— 两千年药用历史的
                  中药瑰宝、 凌驾于时光之上的珍稀植物。
                  石斛文化园以“石斛+”的文创理念， 全业态揭秘石斛的前世今
                  生，穿行于美丽的石斛长廊；倘佯在奇幻的石斛森林；品尝美味
                  的石斛糕点；体验刺激的溜溜探险；若兴致未减，可邀三五小友，
                  至石斛酒文化馆小酌怡情……在石斛文化园将身心彻底放松，感
                  受元气满满的休闲时光。
                </div>
              </div>
              <div class="layout_right">
                <img :src="require('../assets/image/play/g2_banner.png')" alt />
              </div>
            </div>

            <!-- <div class="picsGroup group">
              <div>
                <img src alt />
              </div>
              <div class="infoWrap">
                <span class="title"></span>
                <div class="gap"></div>
              </div>
            </div>-->
          </div>
          <!-- 石斛文化园---图片展示------- -->
          <div class="picsGroups group">
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g2_pic1.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title textline1">石斛森林</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g2_pic2.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">石斛文化馆</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g2_pic3.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">铁忘忧酒文化馆</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g2_pic4.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">铁花生活馆</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 农耕乐园 -->
        <div id="gNongGengLeYuan" class="nongGengLeYuanWrap group">
          <div class="titleWrap">
            <img class="titleLogo" :src="require('../assets/image/play/g3_title.png')" alt />
          </div>
          <!-- 农耕乐园----简介--------- -->
          <div class="contentWrap">
            <div class="summaryWrap">
              <div class="layout_left">
                <div class="titleWrap">
                  <span class="title textline1">农耕乐园</span>
                  <span class="subtitle textline1">NONG GENG LE YUAN</span>
                  <div class="gap"></div>
                </div>
                <div
                  class="detailWrap textline7"
                >一首《二十四节气歌》，镌刻着农耕文明的印记和精神符码。让我们一起 走进农耕乐园，探寻农耕文化的神奇魅力吧。在耕乐馆，亲眼见证农耕文明的历史演变；在农村生活家，感受最真实的农村生活场景再现；在食光穗稻，了解一粒米的诞生历程；在耕食好物，品尝舌尖上的乡村美食；在耕乐小学堂，下田实践田园的春耕秋收；在萌宠乐园，感受人与动物的和谐相处。这样寻常的农家田园景象，是每个人的桃花源梦想。</div>
              </div>
              <div class="layout_right">
                <img :src="require('../assets/image/play/g3_banner.png')" alt />
              </div>
            </div>

            <!-- <div class="picsGroup group">
              <div>
                <img src alt />
              </div>
              <div class="infoWrap">
                <span class="title"></span>
                <div class="gap"></div>
              </div>
            </div>-->
          </div>
          <!-- 农耕乐园---图片展示------- -->
          <div class="picsGroups group">
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g3_pic1.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">耕乐小学堂</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g3_pic2.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">时光穗道</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g3_pic3.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">耕乐馆</span>
              </div>
            </div>
            <div class="picGroup ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/play/g3_pic4.jpg')" alt />
              </div>
              <div class="infoWrap">
                <div class="gap"></div>
                <span class="title">Bon米香</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
import { checkIsNumber } from "@/utils/util.js";

import Swiper from "swiper";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      activeName: "liuLiuLeYuan",
      barFixed: false,
      bannerImg: [
        {
          url: "c_1.png",
          title: "溜溜乐园",
          activeName: "liuLiuLeYuan",
          id: "gLiuLiuLeYuan"
        },
        {
          url: "c_2.png",
          title: "石斛文化园",
          activeName: "shiHu",
          id: "gShiHuWenHuaYuan"
        },
        {
          url: "c_3.png",
          title: "农耕乐园",
          activeName: "nongGeng",
          id: "gNongGengLeYuan"
        }
      ]
    };
  },
  created() {
    this.$nextTick(function() {
      this.galleryThumbsIns();
      // this.galleryTopIns();
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goNavArea(anchorId) {
      let anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    clickMenuItem(anchorId, e) {
      // console.log("e:",e);
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
      this.goNavArea(anchorId);
    },
    // galleryTopIns() {
    //   this.galleryTop = new Swiper(".gallery-top", {
    //     spaceBetween: 0,
    //     loop: true,
    //     loopedSlides: 4, //looped slides should be the same
    //     navigation: {
    //       nextEl: ".swiper-button-next",
    //       prevEl: ".swiper-button-prev"
    //     },
    //     thumbs: {
    //       swiper: this.galleryThumbs
    //     }
    //   });
    // },
    galleryThumbsIns() {
      this.galleryThumbs = new Swiper(".gallery-thumbs", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        spaceBetween: 10,
        slidesPerView: 3,
        loop: true,
        freeMode: true,
        loopedSlides: 4, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (!document.querySelector("#bannerMenu")) return;
      let distOffsetTop = document.querySelector("#bannerMenu").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }

      if (scrollTop > distOffsetTop + 150) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到溜溜乐园的范围383
      if (scrollTop > 383) {
        this.activeName = "liuLiuLeYuan";
      }
      // 滚动到石斛文化园的范围1416
      if (scrollTop > 1400) {
        this.activeName = "shiHu";
      }

      //滚动到农耕乐园的范围2435 nongGeng
      if (scrollTop > 2405) {
        this.activeName = "nongGeng";
      }

      // console.log("window scrollTop:", scrollTop);
      // console.log(
      //   "目标 distOffsetTop:",
      //   distOffsetTop,
      //   document.querySelector("#bannerMenu")
      // );
      // console.log("fixed标识：", this.barFixed);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

//  公用-------------
.titleLogo {
  cursor: default;
}
.group > .titleWrap {
  position: relative;
  width: 100%;
  height: 101px;
  padding: 119px 0 90px 0;
  // background: $bg-blue;
  .titleCenter {
    position: absolute;
    width: 554px;
    height: 115px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0px 73px;
    background: $main-white;

    > img {
      vertical-align: top;
    }
    .titleLogo {
      position: absolute;
      top: -40px;
    }
    .layout_right {
      position: relative;
      display: inline-block;
      padding-left: 30px;

      > .subtitle {
        position: absolute;
        top: 44px;
        left: 8px;
        z-index: 0;
      }
      > .title {
        display: block;
        color: $main-black;
        font-size: 33px;
        padding-top: 3px;
      }
    }
  }
  > .gap {
    position: absolute;
    width: 100%;
    height: 4px;
    background: $main-greenGrass;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: -2;
  }
}
.contentWrap {
  > .summaryWrap {
    > .layout_left {
      width: 500px;
      height: 378px;
      padding: 49px 45px 30px 25px;
      display: inline-block;
      // background: $main-greenGrass;
      box-sizing: border-box;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
      > .titleWrap {
        margin-bottom: 27px;
        > .title,
        .subtitle {
          display: block;
        }
        > .title {
          font-size: 28px;
          font-weight: bold;
          // color: $main-white;
        }
        > .subtitle {
          font-size: 23px;
          font-weight: bold;
          // color: $font-green;
        }
        > .gap {
          width: 30px;
          height: 2px;
          background: $main-white;
          margin-top: 22px;
        }
      }
      .detailWrap {
        height: 168px;
        font-size: 14px;
        color: $main-white;
        line-height: 2;
        overflow: hidden;
        padding-bottom: 20px;
      }
    }
    > .layout_right {
      // background: red;
      width: calc(100% - 500px);
      height: 378px;
      display: inline-block;
      text-align: right;
    }
  }
}

.picsGroups {
  margin-top: 36px;
  > .picGroup {
    width: 340px;
    box-shadow: 5px 2px 5px $font-gray;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
    > .imgWrap {
      width: 340px;
      height: 269px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > .infoWrap {
      position: relative;
      height: 72px;
      padding: 0 9px;
      background: $main-white;

      .title {
        position: absolute;
        display: block;
        width: 154px;
        height: 20px;
        left: 0;
        right: 0;
        font-size: 18px;
        background: $main-white;
        z-index: 0;
        margin: 28px auto 26px auto;
        text-align: center;
      }
      .gap {
        position: absolute;
        width: 100%;
        height: 1px;
        background: $font-grayDark;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 0;
      }
    }
  }
}

// 私有--------------------
.playWrap {
  // margin-top: 36px;
  margin-bottom: 100px;
  // border: 1px solid transparent;
  border-bottom: 1px solid transparent;
  > img {
    width: 100%;
  }
  > .bannerWrap {
    position: relative;
    // height:281px;
    > img {
      width: 100%;
      height: 396px;
    }

    .bannerBox {
      position: absolute;
      bottom: 12px;
      width: 100%;
      height: 400px;
      // position: relative;
      margin-left: auto;
      margin-right: auto;

      > .btnWrap {
        position: absolute;
        width: 1550px;
        height: 180px;
        bottom: 12px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        // z-index:1;
      }

      > .swiper-container {
        width: 100%;
        height: 400px;

        // border: 1px solid red;
        .swiper-slide {
          text-align: center;
          background-size: cover;
          background-position: center;
        }
      }

      .gallery-thumbs {
        position: absolute;
        width: 1400px;
        height: 180px;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 16px;
        box-sizing: border-box;
        // z-index:2;
        // background: $main-white;
        .swiper-slide {
          // background:$main-white;
          height: 100%;
          // opacity: 0.5;
          text-align: center;
          // line-height: 60px;
          &.liuLiu {
            > .menuThumbWrap {
              border: 8px solid $main-blue;
            }
          }
          &.shiHu {
            > .menuThumbWrap {
              border: 8px solid $main-red;
            }
          }
          &.nongGeng {
            > .menuThumbWrap {
              border: 8px solid $main-greenGrass;
            }
          }
          > a,
          > a:focus,
          > a:hover,
          > a.active {
            color: $font-grayDarkD;
          }

          > .menuThumbWrap {
            position: relative;
            display: block;
            // width: 435px;
            text-align: center;
            overflow: hidden;
            border: 8px solid $main-blue;
            border-radius: 14px;
            cursor: pointer;

            > .imgWrap {
              width: 100%;
              // height: 100%;
              // width: 435px;
              height: 123px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            > .infoWrap {
              position: relative;
              height: 41px;
              padding: 0 9px;
              background: $main-white;

              box-sizing: border-box;
              .title {
                position: absolute;
                display: block;
                width: 154px;
                height: 16px;
                line-height: 16px;
                top: 2px;
                left: 0;
                right: 0;
                font-size: 16px;
                background: #fff;
                z-index: 0;
                margin: 9px auto 0px auto;
                text-align: center;
              }
              .gap {
                position: absolute;
                width: 100%;
                height: 1px;
                background: $font-grayDark;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
              }
            }
          }
        }
        .swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }

    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1400px;
      z-index: 99998;
      display: none;
      left: 0;
      right: 0;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 464px;
        height: 50px;
        line-height: 50px;
        background: $font-grayDarkD;
        font-size: 27px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        overflow: hidden;
        cursor: pointer;

        > img {
          margin-right: 10px;
        }
        &.liuLiuLeYuan {
          border-bottom: 10px solid $main-blue;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $main-blue;
          }
        }
        &.shiHu {
          border-bottom: 10px solid $main-red;
          &:hover,
          &.active {
            background: $main-red;
          }
        }
        &.nongGeng {
          border-bottom: 10px solid $main-greenGrass;
          // border-bottom-right-radius: 10px;
          &:hover,
          &.active {
            background: $main-greenGrass;
          }
        }
      }
    }
    > .menusWrap {
      position: absolute;
      width: 1400px;
      bottom: 23px;
      left: 0;
      right: 0;
      margin: auto;
      > .menuWrap {
        position: relative;
        text-align: center;
        display: inline-block;
        overflow: hidden;

        &.liuliuLeYuan {
          border: 4px solid $main-blue;
        }
        &.shiHu {
          border: 4px solid $main-red;
        }
        &.nongGeng {
          border: 4px solid $main-greenGrass;
          margin-right: 0;
        }
      }

      > .picGroup {
        width: 435px;
        box-shadow: 5px 2px 5px $font-gray;
        border-radius: 10px;
        display: inline-block;
        margin-right: 33px;
        > .imgWrap {
          width: 435px;
          height: 123px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .infoWrap {
          position: relative;
          height: 41px;
          padding: 0 9px;
          background: $main-white;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          .title {
            position: absolute;
            display: block;
            width: 154px;
            height: 16px;
            left: 0;
            right: 0;
            font-size: 16px;
            background: #fff;
            z-index: 0;
            margin: 9px auto 0px auto;
            text-align: center;
          }
          .gap {
            position: absolute;
            width: 100%;
            height: 1px;
            background: $font-grayDark;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 0;
          }
        }
      }
    }
  }

  > .menusWrap {
    display: flex;
    margin-top: 90px;
    justify-content: space-between;

    .menuWrap {
      position: relative;
      text-align: center;

      .menu {
        width: 285px;
        height: 90px;
        line-height: 90px;
        position: relative;
        border-radius: 53px;
        span {
          text-align: center;
          color: $main-white;
          font-size: 27px;
          font-weight: bold;
        }
        > img {
          position: absolute;
          left: -50px;
          bottom: 0;
        }
      }
      .menu.xiCan {
        background: $main-red;
      }
      .menu.zhongCan {
        background: $main-yellow;
      }
      .menu.hongBei {
        background: $main-blue;
      }
    }

    .menuWrap.xiCan {
      margin-left: 45px;
    }
  }
  // 溜溜乐园
  .liuLiuLeYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    // 溜溜乐园---题目----------
    > .titleWrap {
      .titleCenter {
        background: $main-white;

        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-blue;
      }
    }
    // 溜溜乐园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-blue;

          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $main-purple;
            }
          }
        }
      }
    }
    // 溜溜乐园----图片展示---------
    > .picsGroups {
      > .picGroup {
        cursor: pointer;
        > .infoWrap {
          .title {
            color: $main-blue;
          }
        }
      }
    }
  }
  // 溜溜乐园end----------

  // 石斛文化园
  .shiHuWenHuaYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    > .titleWrap {
      // padding-bottom: 94px;
      .titleCenter {
        .titleLogo {
          left: 25px;
        }
        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-red;
      }
    }
    // 石斛文化园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-red;

          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $main-orange;
            }
          }
        }
      }
    }
    // 石斛文化园----图片展示---------
    > .picsGroups {
      > .picGroup {
        > .infoWrap {
          .title {
            color: $main-red;
          }
        }
      }
    }
  }

  // 农耕乐园
  .nongGengLeYuanWrap {
    position: relative;
    width: 1400px;
    margin: 0 auto;
    > .titleWrap {
      .titleCenter {
        background: $main-white;
        .titleLogo {
          left: 19px;
        }
        > img {
          vertical-align: top;
        }
        .layout_right {
          > .title {
            color: $main-black;
          }
        }
      }
      > .gap {
        background: $main-greenGrass;
      }
    }
    // 农耕乐园----简介---------
    > .contentWrap {
      > .summaryWrap {
        > .layout_left {
          background: $main-greenGrass;
          > .titleWrap {
            > .title {
              color: $main-white;
            }
            > .subtitle {
              color: $font-green;
            }
          }
        }
      }
    }
    // 农耕乐园----图片展示---------
    > .picsGroups {
      > .picGroup {
        > .infoWrap {
          .title {
            color: $main-greenGrass;
          }
        }
      }
    }
  }
}
#mliuliuLeYuan:after {
  display: none;
}
#mliuliuLeYuan:hover:after {
  display: none;
}
#mShiHu:after {
  display: none;
}
#mShiHu:hover:after {
  display: none;
}

#mNongGeng:after {
  display: none;
}
#mNongGeng:hover:after {
  display: none;
}

@media (max-width: $screen-sp1-min) {
  .playWrap > .bannerWrap .bannerBox > .btnWrap {
    width: 1300px;
  }
}
</style>
